<template>
  <b-modal
    v-model="showModal"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Modal vídeo"
    aria-modal
    @close="close"
  >
    <template #default="props">
      <div
        class="modal-card"
        style="width: auto"
      >
        <section
          v-if="hasVideo"
          class="modal-card-body"
        >
          <youtube
            ref="youtube"
            :video-id="videoId"
          />
        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Volver"
            @click="close"
          />
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "VideoModal",
  props: {
    show: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    }
  },
  data: function(){
    return {
      videoId: this.value,
      showModal: this.show
    }
  },
  computed: {
    hasVideo(){
      return this.videoId !== ''
    }
  },
  watch:{
    value: function(val){
      this.videoId = val
    },
    show: function(val){
      this.showModal = val
    }
  },
  methods: {
    close(){
      this.$emit('close')
    }
  }
}
</script>
