<template>
  <div
    v-if="hasPages"
    class="pager-container"
    :style="{width}"
  >
    <a
      class="control-btn"
      href="#"
      @click.prevent="goToPrev"
    >
      <img
        :src="btn.prev"
        alt=""
      >
    </a>
    <ul class="dots-container">
      <li
        v-for="(item, index) in data"
        :key="index"
        class="dot-item"
        :class="{selected: index===selected}"
      >
        <a
          href="#"
          @click.prevent="goToItem(index)"
        />
      </li>
    </ul>
    <a
      class="control-btn"
      href="#"
      @click.prevent="goToNext"
    >
      <img
        :src="btn.next"
        alt=""
      >
    </a>
  </div>
</template>

<script>
import nextBtn from '@/assets/img/controls/next-item.png'
import prevBtn from '@/assets/img/controls/prev-item.png'

export default {
  name: "PagerBar",
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    selected: {
      type: Number,
      default: 0
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      btn: {
        next: nextBtn,
        prev: prevBtn
      }
    }
  },
  computed: {
    hasPages() {
      return this.data.length > 1
    },
    prevIndex() {
      const prevIndex = this.selected - 1

      return prevIndex < 0 ? this.data.length - 1 : prevIndex
    },
    nextIndex() {
      const nextIndex = this.selected + 1

      return nextIndex >= this.data.length ? 0 : nextIndex
    }
  },
  methods: {
    goToItem(index) {
      this.$emit('change', {index: index, type: 'direct'})
    },
    goToPrev() {
      this.$emit('change', {index: this.prevIndex, type: 'prev'})
    },
    goToNext() {
      this.$emit('change', {index: this.nextIndex, type: 'next'})
    }
  }
}
</script>

<style scoped lang="scss">
.pager-container {
  transition: 0.5s ease-in-out;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  height: 60px;

  .control-btn {
    display: block;

    img {
      width: 50px;
    }
  }

  .dots-container {
    display: flex;
    margin-bottom: 0.5rem;
    overflow-x: auto;
    overflow-y: hidden;

    .dot-item {
      margin-right: 8px;

      a {
        background-color: #333333;
        border-radius: 50%;
        display: inline-block;
        width: 16px;
        height: 16px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      }

      &.selected a {
        background-color: #014b89;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
