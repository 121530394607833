<template>
  <div
    v-if="pages"
    class="p-2"
  >
    <a
      v-if="pages.length > 1"
      href="#"
      @click.prevent="toggleMosaicNavigation"
    >
      <img
        :src="btn.mosaic"
        alt="Navegación por miniaturas"
      >
    </a>
    <a
      v-if="!isFirst"
      href="#"
      @click.prevent="goFirstPage"
    >
      <img
        :src="btn.first"
        alt="Ir a la primera página"
      >
    </a>
    <a
      v-if="hasLinks"
      href="#"
      @click.prevent="toggleLinks"
    >
      <img
        :src="btn.link"
        alt="Enlaces"
      >
    </a>
  </div>
</template>

<script>
import LinkBtn from "@/assets/img/btn/link-btn.png";
import FirstBtn from "@/assets/img/btn/first-btn.png";
import MosaicNavBtn from "@/assets/img/btn/mosaic-nav-btn.png";

export default {
  name: "ScoreRightSidebar",
  props: {
    index: {
      type: Number,
      default: 0
    },
    pages: {
      type: Array,
      default: () => {
        return []
      }
    },
    links: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      btn:{
        link: LinkBtn,
        first: FirstBtn,
        mosaic: MosaicNavBtn
      },
    }
  },
  computed: {
    isFirst(){
      return this.index === 0 || this.pages.length === 1
    },
    hasLinks(){
      return this.links && this.links.length > 0
    }
  },
  methods: {
    toggleLinks() {
      this.$emit('toggle-links')
    },
    goFirstPage() {
      this.$emit('go-to', {type: 'next', index: 0})
    },
    toggleMosaicNavigation(){
      this.$emit('toggle-mosaic')
    }
  }
}
</script>

<style scoped>

</style>
