<template>
  <div
    ref="container"
    class="page has-background-blue with-title"
    style="position: relative;"
  >
    <div
      ref="imageContainer"
      class="image-container"
      :style="{width: containerWidth, height: containerHeight}"
    >
      <template v-for="(page, index) in itemPages">
        <img
          v-if="index === selectedPage"
          :key="page.id"
          :src="getImagePath(page.image)"
          alt=""
        >
      </template>
    </div>
    <mosaic-navigation
      ref="mosaicNavigation"
      :opened="mosaicNavigationOpened"
      :pages="itemPages"
      :width="containerWidth"
      :height="containerFullHeight"
      @change="goToPage"
    />
    <score-resource-navigation
      ref="resourceNavigation"
      :opened="resourceNavigationOpened"
      :resources="itemLinks"
      :width="containerWidth"
      :height="containerFullHeight"
      @open-video="videoModalOpen"
    />
    <right-side-menu
      v-if="showRightSideMenu"
      @open="rightSideOpenChange"
    >
      <score-right-sidebar
        :index="selectedPage"
        :pages="itemPages"
        :audio-status="audioStatus"
        :links="itemLinks"
        @go-to="goToPage"
        @toggle-mosaic="toggleMosaicNavigationOpened"
        @toggle-links="toggleResourceNavigationOpened"
        @toggle-audio="toggleAudio"
      />
    </right-side-menu>
    <video-modal
      v-model="videoModal.videoId"
      :show="videoModal.show"
      @close="videoModalClose"
    />
    <audio-player-play-list
      ref="audioPlayer"
      tempo-variant="general"
      :class="{'with-pager': itemPages.length > 1}"
      :playlist="itemAudios"
      :selected-index="selectedAudio"
      :has-tempo="true"
      :track-mode="currentMode"
      :buttons="showedButtons"
      :preload="true"
      :width="containerWidth"
      @change-status="changeAudioStatus"
      @change-cue="changeAudioCue"
      @loaded="audioLoaded"
    >
      <template #extra-slot>
        <template v-for="(audio,index) in itemAudios">
          <a
            v-if="selectedAudio === index"
            :key="index"
            class="control-btn"
            :class="{disabled: hasSingleAudio}"
            href="#"
            @click.prevent="switchAudioType(audio.type)"
          >
            <img
              :src="trackBtn[audio.type]"
              :alt="audio.type"
            >
          </a>
        </template>
        <a
          class="control-btn"
          :class="{disabled: !isAudioLoaded}"
          href="#"
          @click.prevent="toggleStudioMode"
        >
          <img
            :src="studioModeBtn"
            alt="Modo estudio"
          >
        </a>
      </template>
    </audio-player-play-list>
    <pager-bar
      ref="pagerBar"
      :data="itemPages"
      :selected="selectedPage"
      :width="containerWidth"
      @change="goToPage"
    />
  </div>
</template>

<script>
import ImageUtils from "@/utils/image";
import RightSideMenu from "@/components/RightSideMenu";
import VideoModal from "@/components/modals/VideoModal";
import PagerBar from "@/components/PagerBar";
import MosaicNavigation from "@/components/MosaicNavigation";
import ScoreRightSidebar from "@/components/sidebars/ScoreRightSidebar";
import AudioPlayerPlayList from "@/components/audio/AudioPlayerPlayList";
import VozAudioBtn from "@/assets/img/btn/voz-audio-btn.png";
import InstAudioBtn from "@/assets/img/btn/inst-audio-btn.png";
import StudioModeBtn from "@/assets/img/btn/studio-mode-btn.png"
import ScoreResourceNavigation from "@/components/scores/ScoreResourceNavigation";

export default {
  name: 'ScoreDetail',
  components: {
    ScoreResourceNavigation,
    AudioPlayerPlayList,
    ScoreRightSidebar,
    MosaicNavigation,
    PagerBar, VideoModal, RightSideMenu
  },
  title: 'Partitura',
  data: function () {
    return {
      section_title: 'Presentación',
      backgroundColor: 'transparent',
      containerWidth: '100%',
      containerFullHeight: '100%',
      containerHeight: '100%',
      mosaicNavigationOpened: false,
      resourceNavigationOpened: false,
      data: null,
      selectedPage: 0,
      selectedAudio: 0,
      rightSideOpen: true,
      showRightSideMenu: true,
      videoModal: {
        show: false,
        videoId: ''
      },
      trackBtn: {
        voz: VozAudioBtn,
        inst: InstAudioBtn,
      },
      studioModeBtn: StudioModeBtn,
      playerStatus: 'stop',
      currentMode: 'default',
      showedButtons: {
        prev: false,
        play: true,
        stop: true,
        next: false,
        mode: false,
        tempo: true
      },
      isAudioLoaded: false
    }
  },
  computed: {
    emptyResult() {
      return this.school === null
    },
    userToken() {
      return this.$store.state.token
    },
    currentPage() {
      if (!this.data || !this.data?.sheets) {
        return null
      }
      return this.data.sheets[this.selectedPage]
    },
    itemPages() {
      if (!this.data) {
        return []
      }

      return this.data.sheets
    },
    audioStatus() {
      if (!this.currentPageHasAudio) {
        return null
      }

      return this.playerStatus
    },
    itemLinks() {
      if (!this.data) {
        return []
      }

      return this.data.resources
    },
    itemAudios() {
      const audios = []
      if (!this.data) {
        return audios
      }

      if (this.data.audio_voz && this.data.audio_voz !== '') {
        audios.push({type: 'voz', audio: this.data.audio_voz, default: false})
      }

      if (this.data.audio_kar && this.data.audio_kar !== '') {
        audios.push({type: 'inst', audio: this.data.audio_kar, default: false})
      }

      audios[0].default = true

      return audios
    },
    hasSingleAudio() {
      return this.itemAudios.length <= 1
    }
  },
  watch: {
    userToken() {
      this.loadContent()
    },
    mosaicNavigationOpened(newVal) {
      if (!newVal) {
        this.$store.commit('setRouterInterceptorFnc', null)
      }

      if (newVal) {
        this.$store.commit('setRouterInterceptorFnc', this.closeMosaicNavigationModal.bind(this))
      }
    },
    resourceNavigationOpened(newVal) {
      if (!newVal) {
        this.$store.commit('setRouterInterceptorFnc', null)
      }

      if (newVal) {
        this.$store.commit('setRouterInterceptorFnc', this.closeResourceNavigationModal.bind(this))
      }
    }
  },
  beforeMount() {
    this.loadContent()
  },
  mounted() {
    this.refreshItemSizes()
    window.addEventListener('resize', this.refreshItemSizes)
  },
  destroyed() {
    window.removeEventListener('resize', this.refreshItemSizes)
  },
  beforeDestroy() {
    this.$store.commit('setRouterInterceptorFnc', null)
  },
  methods: {
    getImagePath(img) {
      return ImageUtils.getCDNFullPath(img)
    },
    async loadContent() {
      try {
        await this.$store.dispatch('initLoading')
        const id = this.$route.params.id
        const data = await this.$api.score.getById(id)

        if (!data.hasOwnProperty('resources')) {
          data.resources = []
        }

        this.data = data
        this.setPageTitle(this.data.title)
        this.selectedPage = this.getPageFromCue(0)
        this.selectedAudio = 0

        if (this.data.sheets.length === 1 && this.data.resources.length === 0) {
          this.rightSideOpen = false
          this.showRightSideMenu = false
        }

        await this.refreshItemSizes()

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')
        console.warn(error)
        await this.handleContentError(error)
      }
    },
    goToPage(event) {
      if (event.type === 'prev' && event.index === (this.itemPages.length - 1)) {
        //avoid paginate to last element from first
        return
      }
      this.mosaicNavigationOpened = false
      this.resourceNavigationOpened = false
      this.selectedPage = event.index
      const targetCue = this.getTargetCue(event.index)
      this.$refs.audioPlayer.setCurrentTime(targetCue)
    },
    videoModalOpen(video) {
      if (!video.id) {
        return
      }
      this.videoModal.show = true
      this.videoModal.videoId = video.id
    },
    videoModalClose() {
      this.videoModal.show = false
      this.videoModal.videoId = ''
    },
    rightSideOpenChange(open) {
      this.rightSideOpen = open

      this.refreshItemSizes()
    },
    async refreshItemSizes() {
      await this.$nextTick()
      this.containerWidth = this.rightSideOpen ? (document.body.clientWidth - 80) + 'px' : '100%'
      this.containerFullHeight = this.$refs.container.clientHeight + 'px'
      const fixedBarsHeight = 161
      let itemHeight = this.$refs.container.clientHeight - fixedBarsHeight
      if (this.data?.sheets && this.data.sheets.length <= 1) {
        itemHeight += 60
      }
      itemHeight += 'px'
      this.containerHeight = itemHeight
    },
    swipeHandler(side) {
      let index = side === 'left' ? this.selectedPage + 1 : this.selectedPage - 1
      let type = side === 'left' ? 'next' : 'prev'
      if (index < 0) {
        index = 0
      }
      if (index >= this.itemPages.length) {
        index = 0
      }

      this.goToPage({index, type})
    },
    changeAudioStatus(status) {
      this.playerStatus = status
    },
    changeAudioCue(event) {
      const cuePage = this.getPageFromCue(event.current_time * 1000)
      if (cuePage !== this.selectedPage) {
        this.selectedPage = cuePage
      }
    },
    audioLoaded(event) {
      this.isAudioLoaded = true
    },
    toggleMosaicNavigationOpened() {
      this.resourceNavigationOpened = false
      this.mosaicNavigationOpened = !this.mosaicNavigationOpened
    },
    toggleResourceNavigationOpened() {
      this.mosaicNavigationOpened = false
      this.resourceNavigationOpened = !this.resourceNavigationOpened
    },
    toggleAudio() {
      if (this.playerStatus === 'play') {
        this.$refs.audioPlayer.playClicked()
      }

      if (this.playerStatus === 'pause' ||
        this.playerStatus === 'stop') {
        this.$refs.audioPlayer.playClicked()
      }
    },
    getTargetCue(pageIndex) {
      if (pageIndex <= 0) {
        return 0
      }
      pageIndex--

      return this.itemPages[pageIndex].cue
    },
    getPageFromCue(cue) {
      let foundPage = 0
      if (this.itemPages.length === 1) {
        return foundPage
      }

      const pages = this.itemPages.length
      for (let index = 0; index < pages; index++) {
        const page = this.itemPages[index]
        foundPage = index
        if (page.cue > cue) {
          break
        }
      }

      return foundPage
    },
    switchAudioType(currentType) {
      if (this.hasSingleAudio) {
        return
      }
      const searchedType = currentType === 'voz' ? 'inst' : 'voz'
      const foundAudioIndex = this.itemAudios.findIndex(audio => audio.type === searchedType)
      if (this.audioStatus !== 'stop') {
        this.$refs.audioPlayer.stopAudio()
      }
      if (foundAudioIndex > -1) {
        this.selectedAudio = foundAudioIndex
      }
    },
    toggleStudioMode() {
      this.currentMode = this.currentMode === 'default' ? 'studio' : 'default'
    },
    closeResourceNavigationModal() {
      this.resourceNavigationOpened = false
    },
    closeMosaicNavigationModal() {
      this.mosaicNavigationOpened = false
    }
  }
}
</script>

<style scoped lang="scss">
.audio-player {
  z-index: 11;
  transition: width 0.5s ease-in-out;

  &.with-pager {
    bottom: 60px !important;
  }
}

.image-container {
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;

  img {
    width: auto;
    height: auto;
    object-fit: contain;
  }
}
</style>
