<template>
  <div
    v-show="opened"
    class="list-navigation"
    :style="{width, height}"
  >
    <div class="item title p-3 mb-0">
      Enlaces
    </div>
    <div
      v-for="resource in resources"
      :key="resource.id"
      class="item"
    >
      <div
        v-if="resource.type === 'url'"
        style="padding: 10px;"
      >
        <div />
        <div v-if="resource.description">
          {{ resource.description }}
        </div>
        <a
          :href="resource.link"
          target="_blank"
        >Ver enlace</a>
      </div>
      <div
        v-if="resource.type === 'yt_code'"
        style="padding: 10px;"
      >
        <div />
        <div v-if="resource.description">
          {{ resource.description }}
        </div>
        <a
          href="#"
          @click.prevent="openVideo(resource.link)"
        >Ver vídeo</a>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUtils from "@/utils/image";

export default {
  name: "ScoreResourceNavigation",
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    resources: {
      type: Array,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  },
  methods: {
    getImagePath(img) {
      return ImageUtils.getCDNFullPath(img)
    },
    openVideo(code) {
      this.$emit('open-video', {id: code, show: true})
    }
  }
}
</script>

<style scoped>
.list-navigation {
  position: absolute;
  left: 0;
  top: 0;
  background-color: white;
  z-index: 12;
  transition: width .5s ease-in-out;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
}

.list-navigation .item {
  width: 100%;
  cursor: pointer;
}
.list-navigation .item img {
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
</style>
