<template>
  <div
    v-show="opened"
    class="mosaic-navigation"
    :style="{width, height}"
  >
    <div
      v-for="(page, index) in pages"
      :key="page.id"
      class="item"
    >
      <div
        style="padding: 10px;"
        @click="goToPage(index)"
      >
        <img
          :src="getImagePath(page.image)"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
import ImageUtils from "@/utils/image";

export default {
  name: "MosaicNavigation",
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    pages: {
      type: Array,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    }
  },
  methods: {
    getImagePath(img) {
      return ImageUtils.getCDNFullPath(img)
    },
    goToPage(value) {
      this.$emit('change', {type: 'next', index: value})
    }
  }
}
</script>

<style scoped>
.mosaic-navigation {
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 40px;
  background-color: white;
  z-index: 12;
  transition: width .5s ease-in-out;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
}

.mosaic-navigation .item {
  width: 25%;
  display: inline-block;
  cursor: pointer;
}
.mosaic-navigation .item img {
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
</style>
